<button mat-button [matMenuTriggerFor]="menu">
  <span>{{ currentLanguage?.name }}</span>
  <etn-icon [name]="icon.name" [size]="icon.size"></etn-icon>
</button>

<mat-menu #menu="matMenu">
  @for (language of languages; track language.id) {
    <button mat-menu-item (click)="languageChange.emit(language)">
      {{ language.description || language.name }}
    </button>
  }
</mat-menu>
